@import '~antd/dist/antd.css';

html {
  height: 100%
}

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  height: 100%
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.simple-cloud .tag-cloud-tag {
  cursor: pointer;
}

.ant-page-header-content {
  padding: 24px;
  /* min-height: 280px; */
}

.ant-menu-item a {
  color: #DA3F56 !important;
}

.ant-menu-submenu > .ant-menu {
  background-color: #FFFFFF !important;
  color: black !important;
}

.ant-table-thead .ant-table-cell {
  background-color: #FAB1B4;
  color: #FFFFFF
}




